/*
  @page tax list
*/

//Import require libraries
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Helper from "./../../util/Helper";

/*
  @const Page styles
*/
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  taxTypeLabel: {
    fontSize: "13px",
    marginTop: "8px",
  },
};

/*
  @function Display alert for error message
*/
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/*
  @function Tax listing page logic
*/
const useStyles = makeStyles(styles);
export default function TableList() {
  const classes = useStyles();
  const [tableData, setTableData] = useState(false);
  const [error, setError] = useState(false);
  const [sucess, setSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [taxId, setTaxId] = useState("");
  const [catDeleteDailog, setCatDeleteDailog] = useState(false);
  const [stateName, setStateName] = useState("");
  const [isBtnDisable, setBtnDisable] = useState(false);
  const [accessPage, setAccessPage] = useState(1);  

  

  /*
    @function For close error popup 
  */
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(false);
    setSuccess(false);
  };

  /*
    @function For get tax list data
  */
  const getTaxList = () => {
    const data = {};
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'get_merchant_taxes', data, {
      headers: headers
    }).then(response => {
      const userArr = [];
      setStateName(response.data.stateName);
      let cnt = 0;
      response.data.taxesData.forEach((eachTax) => {
        var taxesAmt = "";
        if (eachTax.taxes_type == "Flat Amount") { //Check tax type was flat amount
          taxesAmt = Helper.viewCurrencyFormat(parseFloat(eachTax.taxes_amt));
        }
        else {
          taxesAmt = Helper.viewPercentageFormat(parseFloat(eachTax.taxes_amt));
        }

        if (eachTax.name === "City Tax" || eachTax.name === "State Tax" || eachTax.name === "Reduced State Tax") //Check tax name was city tax,state tax or reduced state tax
        {
          cnt++;
        }
        userArr.push([eachTax.id, eachTax.name, eachTax.taxes_type, taxesAmt, <button id="greenediticon" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none' }} onClick={() => handleEditCat(eachTax.id, eachTax.name, eachTax.taxes_type, eachTax.taxes_amt)}><EditIcon style={{height:'20px'}}></EditIcon></button>, <button id="reddeleteicon" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none' }} onClick={() => { setTaxId(eachTax.id); setCatDeleteDailog(true); }}><DeleteForeverIcon></DeleteForeverIcon></button>]);
      });
      if (cnt === 3) //Check if count is equal to 3 than disable button
      {
        setBtnDisable(true);
      } else if (cnt === 0) { //Check if count is equal to 0 than enable button
        setBtnDisable(false);
      }
      setTableData(userArr);

    }).catch(error => {      
      return error;
    });
  }
  useEffect(() => {
    const permissonData = JSON.parse(localStorage.getItem('permissonData'));   
     
     if(permissonData)
     {
       if(permissonData.access_tax === 0 || permissonData.access_to_online_dashboard === 0)
       {
         setAccessPage(0);
         return;
       }
     }
    getTaxList();

  }, []);

  /*
    @function For handle edit tax
  */
  const handleEditCat = (id, name, type, amt) => {
    setTaxId(id);
    setTaxName(name);
    setTaxType(type);

    if (type == "Flat Amount") //Check if tax type was flat amount
    {
      var taxamt = Helper.viewCurrencyFormat(parseFloat(amt));
    }
    else {
      var taxamt = amt;
    }

    setTaxAmount(taxamt);
    setOpenAddCategory(true);
  }

  /*
    @function For handle delete tax
  */
  const handleDeleteCat = () => {
    setTableData(false);
    setCatDeleteDailog(false);
    const data = { id: taxId };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'delete_merchant_taxes', data, {
      headers: headers
    }).then(response => {
      getTaxList();
      setTaxId(0);
      if (response.data == 1) { //Check if data was deleted or not
        setSuccess(true);
        setSuccessMsg("Tax Deleted Successfully!");
      } else {
        setError(true);
        setErrorMsg("Some Error Occure!!");
      }
    }).catch(error => {
      getTaxList();
      setTaxId(0);
      setError(true);
      setErrorMsg("Some Error Occure!!");
      return error;
    });

  }

  const [openAddCategory, setOpenAddCategory] = React.useState(false);
  const [taxName, setTaxName] = React.useState("");
  const [taxType, setTaxType] = React.useState("Percentage");
  const [taxAmount, setTaxAmount] = React.useState("");

  /*
    @function For tax value validation
  */
  const validateTaxValue = (e) => {
    var val = e.target.value;
    if (val.length < 6) { //Check value was lessthen 6
      var per = val.replace(".", '');
      per = per / 100;
      if (!isNaN(per)) {
        setTaxAmount(parseFloat(per).toFixed(2));
      }


    }
  }

  /*
    @function For empty all tax field
  */
  const emptyAllField = () => {
    setTaxId(0);
    setTaxName("");
    setTaxType("Percentage");
    setTaxAmount("");
  }

  /*
    @function For add extra taxes
  */
  const addExtraTax = () => {
    var addArr = [
      ['City Tax', 1],
      ['State Tax', 10.50],
      ['Reduced State Tax', 6]
    ];
    // for (var i = 0; i < addArr.length; i++) {
    //   var eachTax = addArr[i];

    //   setOpenAddCategory(false);
    //   setTableData(false);
     

    // }

    // const data = { id: 0, name: eachTax[0], taxes_type: taxType, taxes_amt: eachTax[1] };
    const data = {};
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'add_puerto_rico_data', data, {
      headers: headers
    }).then(response => {
      setSuccess(true);
      setSuccessMsg("Tax Added Successfully!");

    }).catch(error => {
      setError(true);
      setErrorMsg("Some Error Occure!!");
      return error;
    });

    setTimeout(function () {
      getTaxList();
    }, 2000);

  }

  /*
    @function For save tax
  */
  const saveTax = (id) => {
    setOpenAddCategory(false);
    setTableData(false);
    const data = { id: id, name: taxName, taxes_type: taxType, taxes_amt: taxAmount };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'add_merchant_taxes', data, {
      headers: headers
    }).then(response => {
      setTaxId(0);
      setTaxName("");
      setTaxType("Percentage");
      setTaxAmount("");
      getTaxList();
      if (id == 0) { //Check if tax id was 0 than add tax and if not 0 than update tax
        setSuccess(true);
        setSuccessMsg("Tax Added Successfully!");

      }
      else {
        setSuccess(true);
        setSuccessMsg("Tax Updated Successfully!");

      }

    }).catch(error => {
      getTaxList();
      setTaxId(0);
      setError(true);
      setErrorMsg("Some Error Occure!!");
      return error;
    });

  }
  let extraTaxAdd = "";
  if (stateName === "Puerto Rico") //Check id state was puerto rico
  {
    let btnStyle = { backgroundColor: '#ffa726', color: '#FFFFFF' };
    if (isBtnDisable) //Check button was disable
    {
      btnStyle = { backgroundColor: '#D3D3D3', color: '#FFFFFF', cursor: 'not-allowed !important' };
    }
    extraTaxAdd = <sapn style={{ marginLeft: "10px" }}><Button
      id="btnGreenAdd"
      variant="contained"
      color="primary"
      className={classes.button}
      disabled={isBtnDisable}
      style={btnStyle}
      onClick={() => { addExtraTax() }}>Puerto Rico Tax Rates</Button></sapn>;
  }


  if( accessPage === 0)
  { 
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>

            <CardHeader className="cardheader" color="warning">
              <h4 className={classes.cardTitleWhite}>Taxes List</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={12} md={4}></GridItem>
                <center>
                  <h3>You Don't have access of this page!!</h3>
                  
                </center>
              </GridContainer>
            </CardBody>

          </Card>
        </GridItem>
      </GridContainer>);
  }

  if (!tableData) { //Check if table data not come than display loading dialog
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Taxes List</h4>
              <p className={classes.cardCategoryWhite}></p>
            </CardHeader>
            <CardBody>
              <center>
                <h3>Loading Taxes...</h3>
                <CircularProgress id="loginProgress" ></CircularProgress>
              </center>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>);

  }
  return (
    <GridContainer>

        {/* 
          Display error message
        */}
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={error} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {errorMsg}
        </Alert>
      </Snackbar>


      {/* 
          Display success message
        */}
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={sucess} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          {successMsg}
        </Alert>
      </Snackbar>

      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>Tax List</h4>
            <p className={classes.cardCategoryWhite}></p>
          </CardHeader>
          <CardBody>
            <Button
              id="btnGreenAdd"
              variant="contained"
              color="primary"
              className={classes.button}
              style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}
              onClick={() => { emptyAllField(); setOpenAddCategory(true) }}
            >+</Button>
            {extraTaxAdd}
            {/* 
              * Display tax list
            */}
            <Table
              tableHeaderColor="primary"
              tableHead={["ID", "Name", "Type", "Value", "Edit", "Delete"]}
              tableData={tableData}
            />
          </CardBody>
        </Card>
        {/* 
          Add tax form start 
        */}
        <Dialog open={openAddCategory} onClose={() => { setOpenAddCategory(false) }} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{(taxId == 0) ? "Add New " : "Update "}Tax</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Tax Name"
              onChange={(e) => setTaxName(e.target.value)}
              value={taxName}
              fullWidth

            />

            <GridContainer>
              <GridItem xs={12} sm={12} md={6} >

                <InputLabel id="demo-simple-select-label" className={classes.taxTypeLabel}>Tax Type</InputLabel>
                <Select
                  style={{ width: "100%" }}
                  autosize={true}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={(e) => setTaxType(e.target.value)}
                  value={taxType}
                >
                  <MenuItem value={"Percentage"}>Percentage</MenuItem>
                  <MenuItem value={"Flat Amount"}>Flat Amount</MenuItem>
                </Select>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} >

                <TextField
                  autoFocus
                  margin="dense"
                  id="taxAmtInput"
                  label="Tax Value"
                  onChange={(e) => (taxType == "Flat Amount") ? setTaxAmount(Helper.currencyFormatWithDot(e, 'taxAmtInput')) : validateTaxValue(e)}
                  value={taxAmount}
                  fullWidth
                  inputProps={{ min: 0, style: { textAlign: 'right' } }}
                />

              </GridItem>
            </GridContainer>

          </DialogContent>
          <DialogActions>
            <Button id="btnGreyCancel" onClick={() => { setTaxId(0); setTaxName(""); setOpenAddCategory(false) }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              Cancel
            </Button>
            <Button id="btnGreenAdd" onClick={() => { saveTax(taxId, taxName) }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              {(taxId == 0) ? "Add " : "Update"}
            </Button>
          </DialogActions>
        </Dialog>

        {/* 
          Delete ctegory  popup start
        */}
        <Dialog
          open={catDeleteDailog}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{"Are you sure you want to delete Tax?"}</DialogTitle>
          <DialogActions>
            <Button id="btnGreyCancel" onClick={() => { setTaxId(0); setCatDeleteDailog(false) }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              No
            </Button>
            <Button id="btnRedDelete" onClick={handleDeleteCat} color="secondery" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </GridItem>
    </GridContainer>
  );
}