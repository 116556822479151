/*
   @page Dashboard
*/

//Import require libraries
import React, { useEffect, useState } from "react";
import ChartistGraph from "react-chartist";
import { makeStyles } from "@material-ui/core/styles";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import GroupIcon from '@material-ui/icons/Group';
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import { bugs, website, server } from "variables/general.js";
import AssessmentIcon from '@material-ui/icons/Assessment';
import {
  dailySalesChart,
  dailyTransChart,
} from "variables/charts.js";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "components/CustomButtons/Button.js";
import FormControl from '@mui/material/FormControl';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import Table from "components/Table/Table.js";

import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';

/*
   @const Page styles
*/
const useStyles = makeStyles(styles);

/*
   @function Dashboard page logic
*/
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Dashboard() {
  const [totalTransaction, setTotalTransaction] = useState(0);
  const [totalAmt, setTotalAmt] = useState(0);
  const [totalCat, setTotalCat] = useState(0);
  const [totalTaxes, setTotalTaxes] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const [totalMerchant, setTotalMerchant] = useState(0);
  const [weeklyRevenueData, setWeeklyRevenueData] = useState("");
  const [weeklyTransData, setWeeklyTransData] = useState("");


  /*
    @function Get dashboard data
  */
  const getDashbordDetail = () => {
    
    const data = {};
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
    axios.post(window.apiUrl + 'get_dashbord_detail', data, {
      headers: headers
    }).then(response => {

      if (response.data.totalAmount > 1000000) { //Check total amount is greater than 1000000 or not
        var x = response.data.totalAmount / 1000000;
        setTotalAmt(parseInt(x) + " M");
      } else if (response.data.totalAmount > 1000) { //Check total amount is greater than 1000 or not
        var x = response.data.totalAmount / 1000;
        setTotalAmt(parseInt(x) + " K");
      } else {
        setTotalAmt(response.data.totalAmount);
      }

      setTotalTransaction(response.data.totanTransaction);
      setTotalCat(response.data.totalCategory);
      setTotalTaxes(response.data.totalTaxes);
      setTotalItem(response.data.totalItem);
      setTotalMerchant(response.data.totalMerchant);

      var x = [];
      var transData = [];
      var max = 0;
      var transMax = 0;
      response.data.weeklyChartData.map(function (obj, idx) {
        x[idx] = obj.daily_total;
        transData[idx] = obj.total_rec;
        if (parseFloat(max) < parseFloat(obj.daily_total)) { //Check max is lower than daily total or not
          max = obj.daily_total;
        }
        if (parseInt(transMax) < parseInt(obj.total_rec)) { //Check transMax is lower than total_rec or not
          transMax = parseInt(obj.total_rec);
        }
      });

      dailySalesChart.options.high = max;
      dailyTransChart.options.high = transMax;

      /*
        @set Weekly revenue data in graph
      */
      setWeeklyRevenueData({
        labels: ["M", "T", "W", "T", "F", "S", "S"],
        series: [x],
      });

       /*
        @set Weekly transaction data in graph
      */
      setWeeklyTransData({
        labels: ["M", "T", "W", "T", "F", "S", "S"],
        series: [transData],
      });

    }).catch(error => { //set Error message
      
      return error;
    });
  }
  const classes = useStyles();
  const [tableData, setTableData] = useState(false);
  const [error, setError] = useState(false);
  const [sucess, setSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  
  const [filterMId, setFilterMId] = useState("");
  const [filterBusinessName, setFilterBusinessName] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const [batchTransDailog, setBatchTransDailog] = useState(false);
  const [transData, setTransData] = useState(false);
 

  const getBatchTrans = (e, batchId, serial_number) => {
    setBatchTransDailog(true);
    e.preventDefault();
    const data = { batch_id: batchId, serial_number: serial_number };
    const headers =
    {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
   
    axios.post(window.apiUrl + 'get_open_batch_trans', data, {
      headers: headers
    }).then(response => {
      console.log(response);
      var data = [];
      response.data.transData.forEach((eachTrans) => {
          
        let transTime = (eachTrans.device_created_at) ? moment(new Date(eachTrans.device_created_at.replace(" ", "T"))).format('MM/DD/YYYY HH:mm') : "";
        data.push([transTime, eachTrans.ecrref_num, <center>{(eachTrans.edc_type) ? eachTrans.edc_type : eachTrans.payment_method}</center>, <center>{eachTrans.auth_code}</center>, <div style={{ textAlign: 'center' }}>  {(eachTrans.transaction_type == "RETURN")?"-":""}{(eachTrans.ecrref_num) ? "$" : ""}  {(eachTrans.amount) ? eachTrans.amount: ""}</div>]);
        console.log("ddddddd---**");
      });
      setTransData(data);
      console.log(data);
    }).catch(error => {     
      return error;
    });
  }
  
  /*
    @Function get merchant list
  */  
  const getMerchantList = () => {
    const data = { filterBusinessName: filterBusinessName,filterEmail:filterEmail,filterMId:filterMId };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
    axios.post(window.apiUrl+'get_open_batch', data, {
      headers: headers
    }).then(response => {
      console.log("savan");
      const userArr = [];
      response.data.forEach((eachOpenBatch) => {
        // eachOpenBatch.count,
        var substr = eachOpenBatch.business_name;
        if(eachOpenBatch.business_name.length > 24)
        {
          substr = eachOpenBatch.business_name.substring(0, 24)+"..."
        }
        userArr.push([eachOpenBatch.user_id,eachOpenBatch.mid,<span class="spText"><span>{substr}</span><span class="tooltiptext">{eachOpenBatch.business_name}</span></span>,eachOpenBatch.serial_number,<span  class="spText"><span>{eachOpenBatch.nick_name}</span><span class="tooltiptext">{eachOpenBatch.nick_name}</span></span>,eachOpenBatch.version,eachOpenBatch.openBatchDays, eachOpenBatch.created_at,eachOpenBatch.last_login,"$"+eachOpenBatch.amount,<Button id="viewOpenBatchIcon" style={{ width:'100px', color: 'black', background: '#FFFFFF' }} onClick={(e) => { getBatchTrans(e, 0, eachOpenBatch.serial_number) }}><RemoveRedEyeOutlinedIcon></RemoveRedEyeOutlinedIcon></Button>,<Button id="LoginIcon" style={{ width:'100px', color: 'black', background: '#FFFFFF' }} onClick={(e) => { doLogin(eachOpenBatch.user_id,eachOpenBatch.location_id) }}><LoginOutlinedIcon></LoginOutlinedIcon></Button>]);
      });
      console.log("sdssddsdddd");
      console.log(userArr);
      setTableData(userArr);
    }).catch(error => {     
      return error;
    });
  }

 
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(false);
    setSuccess(false);
    setSuccessMsg("");
  };
  useEffect(() => {
    getDashbordDetail();
    getMerchantList();
  }, []);
  
  const doLogin = (merchantId,locationId) => {
    const data = { merchant_id: merchantId };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
    axios.post(window.apiUrl+'login_as_merchant', data, {
      headers: headers
    }).then(response => {
      if (response.data.token) {
        const currentWebUrl =  window.location.href;  
        var localHostMode = "OFF";
        if(currentWebUrl == "http://localhost:3000/admin/table" || currentWebUrl == "http://localhost:3000/admin/table/"){
            localHostMode = "ON";
        } else if(currentWebUrl == "https://devapp.magicpaypos.com/admin/table" || currentWebUrl == "https://devapp.magicpaypos.com/admin/table/"){
            localHostMode = "ON";
        }
        if(response.data.same_version === "0" && localHostMode == "OFF")
        { 
         
           window.open(response.data.react_url+'login/?arg1='+btoa(localStorage.getItem('arg1'))+'&arg2='+btoa(localStorage.getItem('arg2'))+'&arg3='+btoa(merchantId), '_blank');
        }
       else
        {      
          console.log("in elseeeee");  
          localStorage.setItem('merchantToken', response.data.token);
          localStorage.setItem('role', response.data.role);
          localStorage.setItem('parent_id', merchantId);
          localStorage.setItem('location_id',locationId);
          
          window.open('/dashboard', '_blank');
          
        }
  
      }
    }).catch(error => {   
      return error;
    });
  
  }

  if (!tableData) { //Check if table data not come than display loading dialog
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>

          {/* 
            Display error message
          */}
          <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={error} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
              {errorMsg}
            </Alert>
          </Snackbar>

          {/* 
            Display success message
          */}
          <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={sucess} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              {successMsg}
            </Alert>
          </Snackbar>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Dashboard</h4>
              <p className={classes.cardCategoryWhite}>
              </p>
            </CardHeader>
            <CardBody>
              <center>
                <h3>Loading...</h3>
                <CircularProgress id="loginProgress" ></CircularProgress>
              </center>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>);

  }
  return (
    <div>

      {/* 
        @display total transaction, total revenue, total Categories, total Items
      */}
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <AssessmentIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Total Transactions</p>
              <h3 className={classes.cardTitle}>
                {(totalTransaction) ? totalTransaction : <CircularProgress className={"MuiCircularProgress-circleDisableShrink"} />}  </h3>

            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
              
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <AttachMoneyIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Total Revenue</p>
              <h3 className={classes.cardTitle}>{(totalAmt) ? "$" + totalAmt : <CircularProgress className={"MuiCircularProgress-circleDisableShrink"} />}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <GroupIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Total Merchants</p>
              <h3 className={classes.cardTitle}>{(totalMerchant) ? totalMerchant : <CircularProgress className={"MuiCircularProgress-circleDisableShrink"} />}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <DynamicFeedIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Total Items</p>
              <h3 className={classes.cardTitle}>{(totalItem) ? totalItem : <CircularProgress className={"MuiCircularProgress-circleDisableShrink"}
              />}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      
      {/* 
        @display Last Week Revenue, Last Week Transactions graphs
      */}
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card chart>
            <CardHeader color="success">
              <ChartistGraph
                className="ct-chart"
                data={weeklyRevenueData}
                type="Line"
                options={dailySalesChart.options}
                listener={dailySalesChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Last Week All Merchant Revenue</h4>
              {/* <p className={classes.cardCategory}>
                <span className={classes.successText}>
                  <ArrowUpward className={classes.upArrowCardCategory} /> 55%
                </span>{" "}
                increase in today sales.
              </p> */}
            </CardBody>
            {/* <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> updated 4 minutes ago
              </div>
            </CardFooter> */}
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={6}>
          <Card chart>
            <CardHeader color="warning">
              <ChartistGraph
                className="ct-chart"
                data={weeklyTransData}
                type="Line"
                options={dailyTransChart.options}
                listener={dailyTransChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Last Week All Merchant Transactions</h4>
              {/* <p className={classes.cardCategory}>Last Campaign Performance</p> */}
            </CardBody>
            {/* <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> campaign sent 2 days ago
              </div>
            </CardFooter> */}
          </Card>
        </GridItem>
        <Dialog
                id="dialoggggggg"
                  open={batchTransDailog}
                  keepMounted
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-slide-title"
                  aria-describedby="alert-dialog-slide-description"
                  style={{ padding: "0px" }}
                >
        <DialogTitle id="alert-dialog-slide-title">Batch Transactions List</DialogTitle>
        {
          (transData) ? <Table
            tableHeaderColor="primary"
            tableHead={["Trans. Time", "ECR Ref#", <center>Payment Method</center>, <center>Auth Code</center>, <center>Amount</center>]}
            tableData={transData}
          /> : <center><CircularProgress /></center>
        }

        <DialogActions>
          <Button id="btnGreyCancel"  onClick={(e) => { setBatchTransDailog(false); setTransData(false) }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
            Close
          </Button>

        </DialogActions>
      </Dialog>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>Open Batch List</h4>
            <p className={classes.cardCategoryWhite}>
            </p>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
                    {/* <div style={{ height: "25px" }}>&nbsp;</div> */}
                    <FormControl style={{ width: "100%",display:"none" }}>
                      {/* Ammount from textfield */}
                      <TextField
                        id="outlined-basic"
                        variant="outlined"                    
                        margin="full"
                        label="MID"
                        value = { filterMId }
                        onChange={(e) => {setFilterMId(e.target.value)}}                    
                      />
                    </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                    {/* <div style={{ height: "25px" }}>&nbsp;</div> */}
                    <FormControl style={{ width: "100%",display:"none" }}>
                      {/* Ammount from textfield */}
                      <TextField
                        id="outlined-basic"
                        variant="outlined"                    
                        margin="full"
                        label="Business Name"
                        value = {filterBusinessName}
                        onChange={(e) => { setFilterBusinessName(e.target.value) }}                    
                      />
                    </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                    {/* <div style={{ height: "25px" }}>&nbsp;</div> */}
                    <FormControl style={{ width: "100%",display:"none" }}>
                      {/* Ammount from textfield */}
                      <TextField
                        id="outlined-basic"
                        variant="outlined"                    
                        margin="full"
                        label="Email"
                        value = { filterEmail}
                        onChange={(e) => { setFilterEmail(e.target.value) }}                    
                      />
                    </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <center>
                {/* <div style={{ height: "25px",display:"none" }}>&nbsp;</div> */}
                  <FormControl style={{ width: '100%',marginTop:"-5px",display:"none" }} className={classes.popupHalfWidth} xs={12} sm={12} md={3}>
                    <Button onClick = {getMerchantList}  variant="contained" color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', width: '100%', height: '55px' }} className={classes.button}>
                      Search
                    </Button>
                  </FormControl>
                </center>
              </GridItem>
            </GridContainer>
            
            <div id="divBatchRecordTable">
            <Table
              
              tableHeadercolor="warning"
              //"Open Transactions#"
              tableHead={["ID","MID","Business Name", "Serial #","Nickname","Version","Status", "Last Batch","Last Login","Amount","View","Login"]}
              tableData={tableData}
            />
            </div>
            {/* <MUIDataTable
              id="newdatatable"
              // title={"Employee List"}
              data={tableData}
              columns={columns}
              options={options}
            /> */}
          </CardBody>
        </Card>

      </GridItem>
      </GridContainer>
    </div>
  );
}
